import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Link, useHistory, useLocation } from "react-router-dom"
import {
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Row,
  Modal,
} from "reactstrap"
import * as url from "helpers/url_helper"
import SweetAlert from "react-bootstrap-sweetalert"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"
import { AvForm, AvField } from "availity-reactstrap-validation"

//i18n
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"

// import images
import logodark from "../../assets/images/logo-dark.png"
import logolight from "../../assets/images/logo-light.png"
import bgImage from "assets/images/danawa/bg_login.jpg"
import { Button } from "@material-ui/core"

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const DanawaForgotPassword = props => {
  const history = useHistory()
  const query = useQuery()

  const [pwVisibility, setpwVisibility] = useState(true)

  const togglePwVisibility = () => {
    setpwVisibility(!pwVisibility)
  }

  const [token, setToken] = useState(null)
  const [countryCode, setCountryCode] = useState("65")
  const [phoneNumber, setPhoneNumber] = useState(null)
  const [inputOtp, setInputOtp] = useState(null)
  const [newPassword, setNewPassword] = useState("")
  const [retypeNewPassword, setRetypeNewPassword] = useState("")
  const [otp, setOtp] = useState(null)
  const [step, setStep] = useState("New")
  const [type, setType] = useState(null)
  const [oid, setOid] = useState(null)

  useEffect(() => {
    if (localStorage.getItem("authAPI")) {
      const obj = JSON.parse(localStorage.getItem("authAPI"))
      console.log(
        "storage",
        obj.api_token_expiry + " / " + Math.floor(Date.now() / 1000)
      )
      if (obj.api_token_expiry < Math.floor(Date.now() / 1000)) {
        // getToken()
      } else {
        setToken(obj)
      }
    } else {
      // getToken()
    }

    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      history.push("/home")
      // console.log(obj)
    }

    if (query.get("type")) {
      setType(query.get("type"))
    }

    if (query.get("id")) {
      setOid(query.get("id"))
      localStorage.setItem("redirect_id", query.get("id"))
    }

    if (localStorage.getItem("countryCode")) {
      setCountryCode(localStorage.getItem("countryCode"))
    }
  }, [])

  const requestOtp = async e => {
    e.preventDefault()
    if (phoneNumber == null) {
      console.log("email empty")
      seterror_alert(true)
      setdynamic_title(props.t("Email empty"))
      setdynamic_description(props.t("Please enter your email."))
      return
    }

    var urlencoded = new URLSearchParams()
    urlencoded.append("option", "get_otp")
    urlencoded.append("email", phoneNumber)

    const requestOptions = {
      method: "POST",
      // headers: {
      //   Authorization: "Bearer " + token.api_token,
      // },
      body: urlencoded,
    }

    console.log("request otp", urlencoded.toString())

    const response = await fetch(
      url.DANAWA_POST_MEMBER,
      // "http://47.241.176.8/api/external/auth/",
      requestOptions
    )
    const postresponse = await response.json()
    // response.body
    // setPostResponse(postresponse)
    console.log(response.status, postresponse)

    if (response.status == 200 && postresponse.success) {
      if (postresponse.success) {
        setOtp(postresponse.otp)
        setStep("RequestOTP")

        setsuccess_dlg_noaction(true)
        setdynamic_title(props.t("Success"))
        setdynamic_description(postresponse.message)
      }
    } else {
      seterror_alert(true)
      setdynamic_title(props.t("Error"))
      setdynamic_description(postresponse.message)
    }
  }

  const validateOTP = () => {
    if (inputOtp == otp) {
      setStep("OTPValidated")
    } else {
      seterror_alert(true)
      setdynamic_title(props.t("Error"))
      setdynamic_description("Invalid OTP")
    }
  }

  const submitResetPassword = async () => {
    if (phoneNumber == null) {
      seterror_alert(true)
      setdynamic_title(props.t("Email empty"))
      setdynamic_description(props.t("Please enter your email."))
      return
    }

    if (newPassword == "") {
      seterror_alert(true)
      setdynamic_title(props.t("Error"))
      setdynamic_description(props.t("Password must not be empty."))
      return
    }

    if (retypeNewPassword == "") {
      seterror_alert(true)
      setdynamic_title(props.t("Error"))
      setdynamic_description(props.t("Confirm new password must not be empty."))
      return
    }

    if (retypeNewPassword != newPassword) {
      seterror_alert(true)
      setdynamic_title(props.t("Error"))
      setdynamic_description(
        props.t("New password & Confirm new password must be same.")
      )
      return
    }

    var urlencoded = new URLSearchParams()
    urlencoded.append("option", "member_reset_password")
    urlencoded.append("email", phoneNumber)
    urlencoded.append("password", newPassword)

    const requestOptions = {
      method: "POST",
      // headers: {
      //   Authorization: "Bearer " + token.api_token,
      // },
      body: urlencoded,
    }

    console.log("reset password", urlencoded.toString())

    const response = await fetch(
      url.DANAWA_POST_MEMBER,
      // "http://47.241.176.8/api/external/auth/",
      requestOptions
    )
    const postresponse = await response.json()
    // response.body
    // setPostResponse(postresponse)
    console.log(response.status, postresponse)

    if (response.status == 200 && postresponse.success) {
      if (postresponse.success) {
        setsuccess_dlg(true)
        setdynamic_title(props.t("Success"))
        setdynamic_description(postresponse.message)
      }
    } else {
      seterror_alert(true)
      setdynamic_title(props.t("Error"))
      setdynamic_description(postresponse.message)
    }
  }

  const [loginEnabled, setLoginEnabled] = useState(false)

  function onPhoneNumberChange(e) {
    setPhoneNumber(e.target.value)
    if (e.target.value != "")
      setLoginEnabled(true)
    else setLoginEnabled(false)
  }

  function onOtpChange(e) {
    setInputOtp(e.target.value)
  }

  function onNewPasswordChange(e) {
    setNewPassword(e.target.value)
  }

  function onRetypeNewPasswordChange(e) {
    setRetypeNewPassword(e.target.value)
  }

  const [success_dlg, setsuccess_dlg] = useState(false)
  const [success_dlg_noaction, setsuccess_dlg_noaction] = useState(false)
  const [error_alert, seterror_alert] = useState(false)
  const [dynamic_title, setdynamic_title] = useState("")
  const [dynamic_description, setdynamic_description] = useState("")

  return (
    <React.Fragment>
      <div>
        <MetaTags>
          <title>SRON | Forgot Password</title>
        </MetaTags>
        <Container fluid className="p-0">
          <Row className="g-0">
            {/* <CarouselPage /> */}
            {success_dlg_noaction ? (
              <SweetAlert
                success
                title={dynamic_title}
                onConfirm={() => {
                  setsuccess_dlg_noaction(false)
                }}
              >
                {dynamic_description}
              </SweetAlert>
            ) : null}
            {success_dlg ? (
              <SweetAlert
                info
                title={dynamic_title}
                onConfirm={() => {
                  history.push("/login")
                }}
              >
                {dynamic_description}
              </SweetAlert>
            ) : null}
            {error_alert ? (
              <SweetAlert
                title={dynamic_title}
                danger
                onConfirm={() => {
                  seterror_alert(false)
                }}
              >
                {dynamic_description}
              </SweetAlert>
            ) : null}
            <Col
              // xl={6}
              className="mx-auto"
              style={{
                maxWidth: "480px",
                backgroundColor: "#27417F",
                backgroundImage: `url(${bgImage})`,
                backgroundSize: "cover",
              }}
            >
              {/* <img
                src={bgImage}
                alt=""
                style={{
                  position: "absolute",
                  objectFit: "contain",
                  maxWidth: "480px",
                }}
              /> */}
              <div
                className="auth-full-page-content mx-auto"
                style={{ minHeight: "100vh", maxWidth: "480px" }}
              >
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    {/* <div className=""> */}
                    <Row className="px-3 mb-3 mt-5 mx-0">
                      <Col xs={"auto"} className="pe-0">
                        <Link to="/login">
                          <button className="btn btn-outline-light btn-rounded btn-block waves-effect waves-light font-size-24 text-white">
                            <i className="bx bx-arrow-back align-middle font-size-24" />
                          </button>
                        </Link>
                      </Col>
                    </Row>
                    <div className="mx-3" style={{ marginTop: "150px" }}>
                      <Form>
                        <Row className="mb-3 px-2">
                          <Col>
                            <p className="font-size-24 text-white font-weight-semibold">
                              {props.t("Reset Password")}
                            </p>
                            <span className="font-size-14 text-white">
                              {props.t(
                                "Enter your email address to reset your password"
                              )}
                            </span>
                          </Col>
                        </Row>
                        <Row className="mb-3 px-2">
                          <Col>
                            <Input
                              type="text"
                              className="form-control font-size-16 py-3"
                              id="userMobile"
                              placeholder={props.t("Email Address")}
                              style={{ borderRadius: "8px", border: "none" }}
                              onChange={onPhoneNumberChange}
                              readOnly={step != "New"}
                            />
                          </Col>
                        </Row>
                        {step != "OTPValidated" && (
                          <Row className="mb-3 px-2">
                            <Col>
                              <Input
                                type="password"
                                className="form-control font-size-16 py-3"
                                id="otp"
                                placeholder={props.t("OTP")}
                                style={{ borderRadius: "8px", border: "none" }}
                                onChange={onOtpChange}
                              />
                            </Col>
                          </Row>
                        )}
                        {step != "OTPValidated" && (
                          <Row className="mb-3 justify-content-end">
                            <Col xs={"auto"}>
                              <button
                                className={
                                  "btn btn-link btn-block waves-effect waves-light font-size-16 text-white"
                                }
                                // type="submit"
                                style={{ borderRadius: "8px" }}
                                onClick={requestOtp}
                              >
                                <strong>{props.t("Request OTP")}</strong>
                              </button>
                            </Col>
                          </Row>
                        )}
                        {step == "OTPValidated" && (
                          <Row className="mb-3 px-2">
                            <Col>
                              <Input
                                type="password"
                                className="form-control font-size-16 py-3"
                                id="new_password"
                                placeholder={props.t("New Password")}
                                name="new_password"
                                onChange={onNewPasswordChange}
                                style={{ borderRadius: "8px", border: "none" }}
                              />
                            </Col>
                          </Row>
                        )}
                        {step == "OTPValidated" && (
                          <Row className="mb-3 px-2">
                            <Col>
                              <Input
                                type="password"
                                className="form-control font-size-16 py-3"
                                id="retype_new_password"
                                placeholder={props.t("Confirm New Password")}
                                name="retype_new_password"
                                onChange={onRetypeNewPasswordChange}
                                style={{ borderRadius: "8px", border: "none" }}
                              />
                            </Col>
                          </Row>
                        )}
                      </Form>
                    </div>
                    {step != "New" && (
                      <div
                        className="mt-auto"
                        style={{ backgroundColor: "#27417F" }}
                      >
                        <Row className="my-3 px-4">
                          <Col>
                            {/* <Link to="#"> */}
                            <button
                              className={
                                loginEnabled
                                  ? "btn btn-primary btn-block waves-effect waves-light w-100 font-size-18 py-3 text-uppercase"
                                  : "btn btn-light btn-block waves-effect waves-light w-100 font-size-18 text-muted py-3 text-uppercase"
                              }
                              // type="submit"
                              disabled={!loginEnabled}
                              style={{ borderRadius: "8px" }}
                              onClick={() => {
                                if (step == "OTPValidated") {
                                  submitResetPassword()
                                }

                                if (step == "RequestOTP") {
                                  validateOTP()
                                }
                              }}
                            >
                              {step == "RequestOTP" && (
                                <strong>{props.t("VALIDATE OTP")}</strong>
                              )}
                              {step == "OTPValidated" && (
                                <strong>{props.t("CHANGE PASSWORD")}</strong>
                              )}
                            </button>
                            {/* </Link> */}
                          </Col>
                        </Row>
                      </div>
                    )}
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(DanawaForgotPassword)

DanawaForgotPassword.propTypes = {
  t: PropTypes.any,
}
