import React, { useState, useEffect } from "react"
import MetaTags from "react-meta-tags"
import { Link, useLocation, useHistory } from "react-router-dom"
import {
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  Label,
  Row,
  Card,
  CardImg,
  CardTitle,
  Modal,
  Collapse,
} from "reactstrap"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import * as url from "helpers/url_helper"

//i18n
import PropTypes from "prop-types"
import { withTranslation } from "react-i18next"
import i18n from "i18n"

// import images
import logodark from "../../assets/images/logo-dark.png"
import logolight from "../../assets/images/logo-light.png"
import bgImage from "assets/images/ugifts/loginbg.png"
import bgProfile from "assets/images/ugifts/profile_bg.png"
import bgFriend from "assets/images/ugifts/friend_bg.png"
import inviteFriend from "assets/images/ugifts/invite_friend.png"
import iconPoint from "assets/images/ugifts/icons/profile_point.png"
import iconRibbon from "assets/images/ugifts/icon_ribbon.png"
import inviteFriendBg from "assets/images/ugifts/invite_friend_purple_bg.png"

import { Button } from "@material-ui/core"

import emptyLogin from "assets/images/ugifts/icons/empty/empty_login.png"

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

const languageData = [
  { name: "English", short: "eng" },
  { name: "Chinese", short: "cn" },
  { name: "Japanese", short: "jp" },
]

const DanawaArticleList = props => {
  const query = useQuery()
  const history = useHistory()

  const [token, setToken] = useState(null)
  const [uid, setUid] = useState(null)
  const [basketCount, setBasketCount] = useState(null)
  const [isGuest, setIsGuest] = useState(false)

  useEffect(() => {
    if (localStorage.getItem("authAPI")) {
      const obj = JSON.parse(localStorage.getItem("authAPI"))
      console.log(
        "storage",
        obj.api_token_expiry + " / " + Math.floor(Date.now() / 1000)
      )
      if (obj.api_token_expiry < Math.floor(Date.now() / 1000)) {
        getToken()
      } else {
        setToken(obj)
      }
    } else {
      getToken()
    }

    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"))
      setUid(obj.id)
      console.log(obj)
    } else {
      setIsGuest(true)
    }

    if (localStorage.getItem("basketCount")) {
      setBasketCount(localStorage.getItem("basketCount"))
    }
  }, [])

  const getToken = async () => {
    var urlencoded = new URLSearchParams()
    urlencoded.append("option", "get_api_token")
    urlencoded.append("source", "web")
    urlencoded.append("api_key", process.env.REACT_APP_APIKEY)

    const requestOptions = {
      method: "POST",
      body: urlencoded,
    }

    console.log("Request token", urlencoded.toString())

    const response = await fetch(url.UGIFT_STAGING_POST_AUTH, requestOptions)
    const postresponse = await response.json()

    console.log(response.status, postresponse.message)

    if (response.status == 200 && postresponse.success) {
      setToken(postresponse)
      localStorage.setItem("authAPI", JSON.stringify(postresponse))
      console.log(response.status, token)
    } else {
      // seterror_alert(true)
    }
  }

  useEffect(() => {
    getArticles()
    if (uid != null) {
      // if (!isGuest) {
      //   getFriendList()
      //   getOrderList()
      //   getBasketData()
      //   getUdollar()
      // }
      console.log("use effect")
    }
  }, [uid])

  const [articleData, setArticleData] = useState([])
  const getArticles = async () => {
    // var form = JSON.stringify({
    //   option: "list_package",
    //   type: type,
    // })

    var urlencoded = new URLSearchParams()
    urlencoded.append("option", "list_article")
    // urlencoded.append("type", type)
    // urlencoded.append("password", password)

    const requestOptions = {
      method: "POST",
      body: urlencoded,
    }

    console.log("Request articles", urlencoded)

    const response = await fetch(url.DANAWA_POST_FRONTEND, requestOptions)
    const postresponse = await response.json()

    console.log("list_articles", postresponse)

    if (response.status == 200 && postresponse.success) {
      setArticleData(postresponse.table)
    } else {
      // seterror_alert(true)
    }
  }

  return (
    <React.Fragment>
      <div>
        <MetaTags>
          <title>SRON | Articles</title>
        </MetaTags>
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col
              xl={6}
              className="mx-auto"
              style={{
                maxWidth: "480px",
                backgroundColor: "white",
                // backgroundImage: `url(${bgImage})`,
                // backgroundSize: "cover",
              }}
            >
              {/* <img
                src={bgImage}
                alt=""
                style={{
                  position: "absolute",
                  objectFit: "contain",
                  maxWidth: "480px",
                }}
              /> */}
              <div
                className="auth-full-page-content"
                style={{
                  minHeight: "100vh",
                  // paddingTop: "56px",
                  paddingBottom: "108px",
                  // background: "#EBEBEB",
                }}
              >
                <div className="w-100">
                  {/* <div className="profile-white" /> */}
                  {/* <div className="profile-grey" /> */}
                  <div className="d-flex flex-column h-100 px-4">
                    <Row className="mb-3 mt-5 mx-0">
                      <Col xs={"auto"} className="px-0 pe-3">
                        <Link to="/dashboard">
                          <button className="btn btn-outline-light btn-rounded btn-block waves-effect waves-light font-size-24">
                            <i className="bx bx-arrow-back align-middle font-size-24" />
                          </button>
                        </Link>
                      </Col>
                      <Col className="align-self-center">
                        <span
                          className="mb-0 font-size-24 text-primary"
                          style={{ fontWeight: 700 }}
                        >
                          {props.t("A")}
                        </span>
                        <span
                          className="mb-0 font-size-24 text-secondary"
                          style={{ fontWeight: 700 }}
                        >
                          {props.t("RTICLES")}
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        {articleData &&
                          articleData.map((article, index) => (
                            <Row className="mb-2">
                              <Col key={"article-" + index}>
                                <Link to={"/article-details?id=" + article.id}>
                                  <button
                                    className="btn btn-outline-light btn-block waves-effect waves-light w-100 font-size-20 p-3 border-radius-12"
                                    style={{ height: "120px" }}
                                  >
                                    <Row>
                                      <Col className="col-auto">
                                        <img
                                          src={
                                            // "https://dummyimage.com/88x88/000/ffffff.png&text=Voucher"
                                            article.image
                                          }
                                          className="avatar-lg"
                                          alt=""
                                          style={{
                                            borderRadius: "8px",
                                            width: "132px",
                                            height: "88px",
                                            objectFit: "cover",
                                          }}
                                        />
                                      </Col>
                                      <Col className="text-start text-dark font-size-16">
                                        {article.title}
                                      </Col>
                                      <Col className="col-auto">
                                        <i className="bx bx-chevron-right align-middle font-size-30 text-lighter" />
                                      </Col>
                                    </Row>
                                  </button>
                                </Link>
                              </Col>
                            </Row>
                          ))}
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(DanawaArticleList)

DanawaArticleList.propTypes = {
  t: PropTypes.any,
}
