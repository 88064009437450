import React from "react"
import { Redirect } from "react-router-dom"

// // // Pages Component
// import Chat from "../pages/Chat/Chat"

// // // File Manager
// import FileManager from "../pages/FileManager/index"

// // Profile
// import UserProfile from "../pages/Authentication/user-profile"

// // Pages Calendar
// import Calendar from "../pages/Calendar/index"

// // //Tasks
// import TasksList from "../pages/Tasks/tasks-list"
// import TasksKanban from "../pages/Tasks/tasks-kanban"
// import TasksCreate from "../pages/Tasks/tasks-create"

// // //Projects
// import ProjectsGrid from "../pages/Projects/projects-grid"
// import ProjectsList from "../pages/Projects/projects-list"
// import ProjectsOverview from "../pages/Projects/ProjectOverview/projects-overview"
// import ProjectsCreate from "../pages/Projects/projects-create"

// // //Ecommerce Pages
// import EcommerceProducts from "../pages/Ecommerce/EcommerceProducts/index"
// import EcommerceProductDetail from "../pages/Ecommerce/EcommerceProducts/EcommerceProductDetail"
// import EcommerceOrders from "../pages/Ecommerce/EcommerceOrders/index"
// import EcommerceCustomers from "../pages/Ecommerce/EcommerceCustomers/index"
// import EcommerceCart from "../pages/Ecommerce/EcommerceCart"
// import EcommerceCheckout from "../pages/Ecommerce/EcommerceCheckout"
// import EcommerceShops from "../pages/Ecommerce/EcommerceShops/index"
// import EcommerceAddProduct from "../pages/Ecommerce/EcommerceAddProduct"

// //Email
// import EmailInbox from "../pages/Email/email-inbox"
// import EmailRead from "../pages/Email/email-read"
// import EmailBasicTemplte from "../pages/Email/email-basic-templte"
// import EmailAlertTemplte from "../pages/Email/email-template-alert"
// import EmailTemplateBilling from "../pages/Email/email-template-billing"

// //Invoices
// import InvoicesList from "../pages/Invoices/invoices-list"
// import InvoiceDetail from "../pages/Invoices/invoices-detail"

// Authentication related pages
// import Login from "../pages/Authentication/Login"
// import Logout from "../pages/Authentication/Logout"
// import Register from "../pages/Authentication/Register"
// import ForgetPwd from "../pages/Authentication/ForgetPassword"

// //  // Inner Authentication
// import Login1 from "../pages/AuthenticationInner/Login"
// import Login2 from "../pages/AuthenticationInner/Login2"
// import Register1 from "../pages/AuthenticationInner/Register"
// import Register2 from "../pages/AuthenticationInner/Register2"
// import Recoverpw from "../pages/AuthenticationInner/Recoverpw"
// import Recoverpw2 from "../pages/AuthenticationInner/Recoverpw2"
// import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword"
// import LockScreen from "../pages/AuthenticationInner/auth-lock-screen"
// import LockScreen2 from "../pages/AuthenticationInner/auth-lock-screen-2"
// import ConfirmMail from "../pages/AuthenticationInner/page-confirm-mail"
// import ConfirmMail2 from "../pages/AuthenticationInner/page-confirm-mail-2"
// import EmailVerification from "../pages/AuthenticationInner/auth-email-verification"
// import EmailVerification2 from "../pages/AuthenticationInner/auth-email-verification-2"
// import TwostepVerification from "../pages/AuthenticationInner/auth-two-step-verification"
// import TwostepVerification2 from "../pages/AuthenticationInner/auth-two-step-verification-2"

// //G5
// import G5Register from "pages/AuthenticationInner/G5Register"
// import G5RegisterOrganization from "pages/AuthenticationInner/G5RegisterOrganization"
// import G5RegisterUser from "pages/AuthenticationInner/G5RegisterUser"
// import G5Settings from "pages/G5/Admin/Settings/Settings"
// import G5Allowance from "pages/G5/Admin/Settings/Allowance"
// import G5Value from "pages/G5/Admin/Settings/CompanyValue"
// import G5User from "pages/G5/Admin/Settings/UserTables"
// import G5Earning from "pages/G5/Admin/Settings/EarningTables"
// import G5Giving from "pages/G5/Admin/Settings/GivingTables"
// import G5Stripe from "pages/G5/Admin/Settings/Stripe"
// import G5Challenge from "pages/G5/Admin/Settings/Challenges"
// import G5ChallengeUser from "pages/G5/Admin/Settings/ChallengesUser"
// import G5Analytics from "pages/G5/Admin/Settings/Analytics"

//Ugift
// import UgiftRegister from "pages/AuthenticationInner/UgiftRegister"
// import UgiftVerification from "pages/AuthenticationInner/UgiftVerification"
// import UgiftSetPassword from "pages/AuthenticationInner/UgiftSetPassword"
// import UgiftLogin from "pages/AuthenticationInner/UgiftLogin"
// import UgiftForgotPassword from "pages/AuthenticationInner/UgiftForgotPassword"
// import UgiftForgotPasswordVerification from "pages/AuthenticationInner/UgiftForgotPasswordVerification"
// import UgiftResetPassword from "pages/AuthenticationInner/UgiftResetPassword"
// import UgiftHome from "pages/AuthenticationInner/UgiftHome"
// import UgiftProducts from "pages/AuthenticationInner/UgiftProducts"
// import UgiftCategory from "pages/AuthenticationInner/UgiftCategory"
// import UgiftSelectGiftCard from "pages/AuthenticationInner/UgiftSelectGiftCard"
// import UgiftGiftCardProduct from "pages/AuthenticationInner/UgiftGiftCardProduct"
// import UgiftProductDetails from "pages/AuthenticationInner/UgiftProductDetails"
// import UgiftNotification from "pages/AuthenticationInner/UgiftNotification"
// import UgiftVouchers from "pages/AuthenticationInner/UgiftVouchers"
// import UgiftViewVouchers from "pages/AuthenticationInner/UgiftViewVouchers"
// import UgiftVoucherDetails from "pages/AuthenticationInner/UgiftVoucherDetails"
// import UgiftProductsSearch from "pages/AuthenticationInner/UgiftProductsSearch"
// import UgiftProfile from "pages/AuthenticationInner/UgiftProfile"
// import UgiftInviteFriend from "pages/AuthenticationInner/UgiftInviteFriend"
// import UgiftEditProfile from "pages/AuthenticationInner/UgiftEditProfile"
// import UgiftFriends from "pages/AuthenticationInner/UgiftFriends"
// import UgiftBasket from "pages/AuthenticationInner/UgiftBasket"
// import UgiftCheckoutConfirmation from "pages/AuthenticationInner/UgiftCheckoutConfirmation"
// import UgiftCheckoutCardSelection from "pages/AuthenticationInner/UgiftCheckoutCardSelection"
// import UgiftCheckoutCardCustomization from "pages/AuthenticationInner/UgiftCheckoutCardCustomization"
// import UgiftOrderTopay from "pages/AuthenticationInner/UgiftOrderTopay"
// import UgiftOrderMyPurchases from "pages/AuthenticationInner/UgiftOrderMyPurchases"
// import UgiftOrderReceived from "pages/AuthenticationInner/UgiftOrderReceived"
// import UgiftOrderHistoryDetails from "pages/AuthenticationInner/UgiftOrderHistoryDetails"
// import UgiftPaymentStatus from "pages/AuthenticationInner/UgiftPaymentStatus"
// import UgiftGiftCardShare from "pages/AuthenticationInner/UgiftGiftCardShare"
// import UgiftCampaign from "pages/AuthenticationInner/UgiftCampaign"
// import UgiftInviteLink from "pages/AuthenticationInner/UgiftInviteLink"
// import UgiftPrivacyPolicy from "pages/AuthenticationInner/UgiftPrivacyPolicy"
// import UgiftTermsAndCondition from "pages/AuthenticationInner/UgiftTermsAndCondition"
// import UgiftSupport from "pages/AuthenticationInner/UgiftSupport"
// import UgiftContactUs from "pages/AuthenticationInner/UgiftContactUs"
// import UgiftUdollarCardList from "pages/AuthenticationInner/UgiftUdollarCardList"
// import UgiftUdollarCardHistory from "pages/AuthenticationInner/UgiftUdollarCardHistory"

// // Dashboard
// import Dashboard from "../pages/Dashboard/index"
// import DashboardSaas from "../pages/Dashboard-saas/index"
// import DashboardCrypto from "../pages/Dashboard-crypto/index"
// import Blog from "../pages/Dashboard-Blog/index"

// //Crypto
// import CryptoWallet from "../pages/Crypto/CryptoWallet/crypto-wallet"
// import CryptoBuySell from "../pages/Crypto/crypto-buy-sell"
// import CryptoExchange from "../pages/Crypto/crypto-exchange"
// import CryptoLending from "../pages/Crypto/crypto-lending"
// import CryptoOrders from "../pages/Crypto/CryptoOrders/crypto-orders"
// import CryptoKYCApplication from "../pages/Crypto/crypto-kyc-application"
// import CryptoIcoLanding from "../pages/Crypto/CryptoIcoLanding/index"

// // Charts
// import ChartApex from "../pages/Charts/Apexcharts"
// import ChartistChart from "../pages/Charts/ChartistChart"
// import ChartjsChart from "../pages/Charts/ChartjsChart"
// import EChart from "../pages/Charts/EChart"
// import SparklineChart from "../pages/Charts/SparklineChart"
// import ToastUIChart from "../pages/Charts/ToastUIChart"
// import ChartsKnob from "../pages/Charts/charts-knob"

// // Maps
// import MapsGoogle from "../pages/Maps/MapsGoogle"
// import MapsVector from "../pages/Maps/MapsVector"
// import MapsLeaflet from "../pages/Maps/MapsLeaflet"

// //Icons
// import IconBoxicons from "../pages/Icons/IconBoxicons"
// import IconDripicons from "../pages/Icons/IconDripicons"
// import IconMaterialdesign from "../pages/Icons/IconMaterialdesign"
// import IconFontawesome from "../pages/Icons/IconFontawesome"

// //Tables
// import BasicTables from "../pages/Tables/BasicTables"
// import DatatableTables from "../pages/Tables/DatatableTables"
// import ResponsiveTables from "../pages/Tables/ResponsiveTables"
// import EditableTables from "../pages/Tables/EditableTables"

// //Crowdcare
// import DonorTables from "../pages/Tables/DonorTables"
// import ProjectTables from "../pages/Tables/ProjectTables"
// import UserTables from "../pages/Tables/UserTables"
// import AuditLogTables from "../pages/Tables/AuditLogTables"
// import ApplicationTables from "../pages/Tables/ApplicationTables"
// import ApplicationDetail from "../pages/Tables/ApplicationDetail"
// import NewUser from "../pages/Tables/NewUser"
// import NewProject from "../pages/Tables/NewProject"
// import DonationHistory from "../pages/Tables/DonationHistory"
// import DonationInvoice from "../pages/Tables/DonationInvoice"

// //Blog
// import BlogList from "../pages/Blog/BlogList/index"
// import BlogGrid from "../pages/Blog/BlogGrid/index"
// import BlogDetails from "../pages/Blog/BlogDetails"

// // Forms
// import FormElements from "../pages/Forms/FormElements"
// import FormLayouts from "../pages/Forms/FormLayouts"
// import FormAdvanced from "../pages/Forms/FormAdvanced"
// import FormEditors from "../pages/Forms/FormEditors"
// import FormValidations from "../pages/Forms/FormValidations"
// import FormMask from "../pages/Forms/FormMask"
// import FormRepeater from "../pages/Forms/FormRepeater"
// import FormUpload from "../pages/Forms/FormUpload"
// import FormWizard from "../pages/Forms/FormWizard"
// import FormXeditable from "../pages/Forms/FormXeditable"

// //Ui
// import UiAlert from "../pages/Ui/UiAlert"
// import UiButtons from "../pages/Ui/UiButtons"
// import UiCards from "../pages/Ui/UiCards"
// import UiCarousel from "../pages/Ui/UiCarousel"
// import UiColors from "../pages/Ui/UiColors"
// import UiDropdown from "../pages/Ui/UiDropdown"
// import UiGeneral from "../pages/Ui/UiGeneral"
// import UiGrid from "../pages/Ui/UiGrid"
// import UiImages from "../pages/Ui/UiImages"
// import UiLightbox from "../pages/Ui/UiLightbox"
// import UiModal from "../pages/Ui/UiModal"
// import UiProgressbar from "../pages/Ui/UiProgressbar"
// import UiSweetAlert from "../pages/Ui/UiSweetAlert"
// import UiTabsAccordions from "../pages/Ui/UiTabsAccordions"
// import UiTypography from "../pages/Ui/UiTypography"
// import UiVideo from "../pages/Ui/UiVideo"
// import UiSessionTimeout from "../pages/Ui/UiSessionTimeout"
// import UiRating from "../pages/Ui/UiRating"
// import UiRangeSlider from "../pages/Ui/UiRangeSlider"
// import UiNotifications from "../pages/Ui/ui-notifications"

//Pages
// import PagesStarter from "../pages/Utility/pages-starter"
import PagesMaintenance from "../pages/Utility/pages-maintenance"
import PagesComingsoon from "../pages/Utility/pages-comingsoon"
// import PagesTimeline from "../pages/Utility/pages-timeline"
// import PagesFaqs from "../pages/Utility/pages-faqs"
// import PagesPricing from "../pages/Utility/pages-pricing"
import Pages404 from "../pages/Utility/pages-404"
import Pages500 from "../pages/Utility/pages-500"

// Danawa
import DanawaDashboard from "pages/Danawa/DanawaDashboard"
import DanawaLogin from "pages/Danawa/DanawaLogin"
import DanawaLanding from "pages/Danawa/DanawaLanding"
import DanawaForgotPassword from "pages/Danawa/DanawaForgotPassword"
import DanawaSignUp from "pages/Danawa/DanawaSignUp"
import DanawaProfile from "pages/Danawa/DanawaProfile"
import DanawaProfileEdit from "pages/Danawa/DanawaProfileEdit"
import DanawaProductDetails from "pages/Danawa/DanawaProductDetails"
import DanawaArticleDetails from "pages/Danawa/DanawaArticleDetails"
import DanawaArticleList from "pages/Danawa/DanawaArticleList"
import DanawaProductList from "pages/Danawa/DanawaProductList"
import DanawaChangePassword from "pages/Danawa/DanawaChangePassword"

// //Contacts
// import ContactsGrid from "../pages/Contacts/contacts-grid"
// import ContactsList from "../pages/Contacts/ContactList/contacts-list"
// import ContactsProfile from "../pages/Contacts/ContactsProfile/contacts-profile"

const userRoutes = [
  // { path: "/dashboard", component: Dashboard },
  // { path: "/dashboard/:id?", component: () => <Redirect to="/home" /> },
  // { path: "/dashboard-saas", component: DashboardSaas },
  // { path: "/dashboard-crypto", component: DashboardCrypto },
  // { path: "/blog", component: Blog },

  // //Crypto
  // { path: "/crypto-wallet", component: CryptoWallet },
  // { path: "/crypto-buy-sell", component: CryptoBuySell },
  // { path: "/crypto-exchange", component: CryptoExchange },
  // { path: "/crypto-lending", component: CryptoLending },
  // { path: "/crypto-orders", component: CryptoOrders },
  // { path: "/crypto-kyc-application", component: CryptoKYCApplication },

  // //chat
  // { path: "/chat", component: Chat },

  // //File Manager
  // { path: "/apps-filemanager", component: FileManager },

  // // //calendar
  // { path: "/calendar", component: Calendar },

  // // //profile
  // // { path: "/profile", component: UserProfile },

  // //Ecommerce
  // { path: "/ecommerce-products", component: EcommerceProducts },
  // { path: "/ecommerce-product-detail", component: EcommerceProductDetail },
  // { path: "/ecommerce-products/:id", component: EcommerceProductDetail },
  // { path: "/ecommerce-orders", component: EcommerceOrders },
  // { path: "/ecommerce-customers", component: EcommerceCustomers },
  // { path: "/ecommerce-cart", component: EcommerceCart },
  // { path: "/ecommerce-checkout", component: EcommerceCheckout },
  // { path: "/ecommerce-shops", component: EcommerceShops },
  // { path: "/ecommerce-add-product", component: EcommerceAddProduct },

  // //Email
  // { path: "/email-inbox", component: EmailInbox },
  // { path: "/email-read", component: EmailRead },
  // { path: "/email-template-basic", component: EmailBasicTemplte },
  // { path: "/email-template-alert", component: EmailAlertTemplte },
  // { path: "/email-template-billing", component: EmailTemplateBilling },

  // //Invoices
  // { path: "/invoices-list", component: InvoicesList },
  // { path: "/invoices-detail", component: InvoiceDetail },
  // { path: "/invoices-detail/:id", component: InvoiceDetail },

  // // Tasks
  // { path: "/tasks-list", component: TasksList },
  // { path: "/tasks-kanban", component: TasksKanban },
  // { path: "/tasks-create", component: TasksCreate },

  // //Projects
  // { path: "/projects-grid", component: ProjectsGrid },
  // { path: "/projects-list", component: ProjectsList },
  // { path: "/projects-overview", component: ProjectsOverview },
  // { path: "/projects-overview/:id", component: ProjectsOverview },
  // { path: "/projects-create", component: ProjectsCreate },

  // //Blog
  // { path: "/blog-list", component: BlogList },
  // { path: "/blog-grid", component: BlogGrid },
  // { path: "/blog-details", component: BlogDetails },

  // // Contacts
  // { path: "/contacts-grid", component: ContactsGrid },
  // { path: "/contacts-list", component: ContactsList },
  // { path: "/contacts-profile", component: ContactsProfile },

  // //Charts
  // { path: "/apex-charts", component: ChartApex },
  // { path: "/chartist-charts", component: ChartistChart },
  // { path: "/chartjs-charts", component: ChartjsChart },
  // { path: "/e-charts", component: EChart },
  // { path: "/sparkline-charts", component: SparklineChart },
  // { path: "/tui-charts", component: ToastUIChart },
  // { path: "/charts-knob", component: ChartsKnob },

  // // Icons
  // { path: "/icons-boxicons", component: IconBoxicons },
  // { path: "/icons-dripicons", component: IconDripicons },
  // { path: "/icons-materialdesign", component: IconMaterialdesign },
  // { path: "/icons-fontawesome", component: IconFontawesome },

  // // Tables
  // { path: "/tables-basic", component: BasicTables },
  // { path: "/tables-datatable", component: DatatableTables },
  // { path: "/tables-responsive", component: ResponsiveTables },
  // { path: "/tables-editable", component: EditableTables },

  // Crowdcare
  // { path: "/tables-donortable", component: DonorTables },
  // { path: "/tables-donationhistory", component: DonationHistory },
  // { path: "/tables-projecttable", component: ProjectTables },
  // { path: "/tables-usertable", component: UserTables },
  // { path: "/tables-auditlogtable", component: AuditLogTables },
  // { path: "/tables-applicationtable", component: ApplicationTables },
  // { path: "/tables-applicationdetails", component: ApplicationDetail },
  // { path: "/tables-newuser", component: NewUser },
  // { path: "/tables-newproject", component: NewProject },

  // G5
  // { path: "/settings", component: G5Settings },
  // { path: "/allowance", component: G5Allowance },
  // { path: "/value", component: G5Value },
  // { path: "/employees", component: G5User },
  // { path: "/earnings", component: G5Earning },
  // { path: "/givings", component: G5Giving },
  // { path: "/challenges_setting", component: G5Challenge },
  // { path: "/challenges", component: G5ChallengeUser },
  // { path: "/analytics", component: G5Analytics },

  // Ugift
  // { path: "/view-vouchers", component: UgiftViewVouchers },
  // { path: "/voucher-details", component: UgiftVoucherDetails },
  // // { path: "/profile-edit", component: UgiftEditProfile },
  // { path: "/friends", component: UgiftFriends },
  // { path: "/checkout-confirmation", component: UgiftCheckoutConfirmation },
  // { path: "/checkout-cardselection", component: UgiftCheckoutCardSelection },
  // {
  //   path: "/checkout-cardcustomization",
  //   component: UgiftCheckoutCardCustomization,
  // },
  // { path: "/order-topay", component: UgiftOrderTopay },
  // { path: "/order-mypurchases", component: UgiftOrderMyPurchases },
  // { path: "/order-received", component: UgiftOrderReceived },
  // { path: "/order-details", component: UgiftOrderHistoryDetails },
  // { path: "/payment-status", component: UgiftPaymentStatus },
  // { path: "/udollar-list", component: UgiftUdollarCardList },
  // { path: "/udollar-history", component: UgiftUdollarCardHistory },

  // // Maps
  // { path: "/maps-google", component: MapsGoogle },
  // { path: "/maps-vector", component: MapsVector },
  // { path: "/maps-leaflet", component: MapsLeaflet },

  // // Forms
  // { path: "/form-elements", component: FormElements },
  // { path: "/form-layouts", component: FormLayouts },
  // { path: "/form-advanced", component: FormAdvanced },
  // { path: "/form-editors", component: FormEditors },
  // { path: "/form-mask", component: FormMask },
  // { path: "/form-repeater", component: FormRepeater },
  // { path: "/form-uploads", component: FormUpload },
  // { path: "/form-wizard", component: FormWizard },
  // { path: "/form-validation", component: FormValidations },
  // { path: "/form-xeditable", component: FormXeditable },

  // // Ui
  // { path: "/ui-alerts", component: UiAlert },
  // { path: "/ui-buttons", component: UiButtons },
  // { path: "/ui-cards", component: UiCards },
  // { path: "/ui-carousel", component: UiCarousel },
  // { path: "/ui-colors", component: UiColors },
  // { path: "/ui-dropdowns", component: UiDropdown },
  // { path: "/ui-general", component: UiGeneral },
  // { path: "/ui-grid", component: UiGrid },
  // { path: "/ui-images", component: UiImages },
  // { path: "/ui-lightbox", component: UiLightbox },
  // { path: "/ui-modals", component: UiModal },
  // { path: "/ui-progressbars", component: UiProgressbar },
  // { path: "/ui-sweet-alert", component: UiSweetAlert },
  // { path: "/ui-tabs-accordions", component: UiTabsAccordions },
  // { path: "/ui-typography", component: UiTypography },
  // { path: "/ui-video", component: UiVideo },
  // { path: "/ui-session-timeout", component: UiSessionTimeout },
  // { path: "/ui-rating", component: UiRating },
  // { path: "/ui-rangeslider", component: UiRangeSlider },
  // { path: "/ui-notifications", component: UiNotifications },

  // //Utility
  // { path: "/pages-starter", component: PagesStarter },
  // { path: "/pages-timeline", component: PagesTimeline },
  // { path: "/pages-faqs", component: PagesFaqs },
  // { path: "/pages-pricing", component: PagesPricing },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/landing" /> },
]

const authRoutes = [
  // { path: "/logout", component: Logout },
  // // { path: "/login", component: UgiftLogin },
  // // { path: "/login-ori", component: Login },
  // // { path: "/forgot-password", component: UgiftForgotPassword },
  // {
  //   path: "/forgot-password-verification",
  //   component: UgiftForgotPasswordVerification,
  // },
  // // { path: "/register", component: UgiftRegister },
  // { path: "/verification", component: UgiftVerification },
  // { path: "/set-password", component: UgiftSetPassword },
  // { path: "/reset-password", component: UgiftResetPassword },
  // { path: "/giftcard-share", component: UgiftGiftCardShare },
  // { path: "/invitation", component: UgiftInviteLink },
  // { path: "/privacy-policy", component: UgiftPrivacyPolicy },
  // { path: "/terms-n-conditions", component: UgiftTermsAndCondition },
  // { path: "/support", component: UgiftSupport },

  // //ugift public
  // { path: "/invite-friend", component: UgiftInviteFriend },
  // { path: "/notification", component: UgiftNotification },
  // // { path: "/profile", component: UgiftProfile },
  // { path: "/vouchers", component: UgiftVouchers },
  // { path: "/categories", component: UgiftCategory },
  // { path: "/home", component: UgiftHome },
  // { path: "/basket", component: UgiftBasket },
  // { path: "/gift-card", component: UgiftSelectGiftCard },
  // { path: "/gift-card-product", component: UgiftGiftCardProduct },
  // { path: "/products", component: UgiftProducts },
  // { path: "/product-search", component: UgiftProductsSearch },
  // // { path: "/product-details", component: UgiftProductDetails },
  // { path: "/campaign", component: UgiftCampaign },
  // { path: "/contact-us", component: UgiftContactUs },

  { path: "/pages-maintenance", component: PagesMaintenance },
  { path: "/pages-comingsoon", component: PagesComingsoon },
  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },
  // { path: "/crypto-ico-landing", component: CryptoIcoLanding },

  // Danawa
  { path: "/dashboard", component: DanawaDashboard },
  { path: "/login", component: DanawaLogin },
  { path: "/landing", component: DanawaLanding },
  { path: "/forgot-password", component: DanawaForgotPassword },
  { path: "/sign-up", component: DanawaSignUp },
  { path: "/profile", component: DanawaProfile },
  { path: "/profile-edit", component: DanawaProfileEdit },
  { path: "/change-password", component: DanawaChangePassword },
  { path: "/product-list", component: DanawaProductList },
  { path: "/product-details", component: DanawaProductDetails },
  { path: "/article-list", component: DanawaArticleList },
  { path: "/article-details", component: DanawaArticleDetails },

  // { path: "/invoice", component: DonationInvoice },
  // { path: "/new_application", component: ApplicationDetail },

  // Authentication Inner
  // { path: "/pages-login", component: Login1 },
  // { path: "/pages-login-2", component: Login2 },
  // { path: "/pages-register", component: Register1 },
  // { path: "/pages-register-2", component: Register2 },
  // { path: "/page-recoverpw", component: Recoverpw },
  // { path: "/page-recoverpw-2", component: Recoverpw2 },
  // { path: "/pages-forgot-pwd", component: ForgetPwd1 },
  // { path: "/auth-lock-screen", component: LockScreen },
  // { path: "/auth-lock-screen-2", component: LockScreen2 },
  // { path: "/page-confirm-mail", component: ConfirmMail },
  // { path: "/page-confirm-mail-2", component: ConfirmMail2 },
  // { path: "/auth-email-verification", component: EmailVerification },
  // { path: "/auth-email-verification-2", component: EmailVerification2 },
  // { path: "/auth-two-step-verification", component: TwostepVerification },
  // { path: "/auth-two-step-verification-2", component: TwostepVerification2 },

  //G5
  // { path: "/registration", component: G5Register },
  // { path: "/organization", component: G5RegisterOrganization },
  // { path: "/invitation", component: G5RegisterUser },
  // { path: "/stripe", component: G5Stripe },

  { path: "/404", component: Pages404 },
  { path: "*", component: Pages404 },

  { path: "/", exact: true, component: () => <Redirect to="/landing" /> },
]

export { userRoutes, authRoutes }
